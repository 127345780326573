<template>
<Dialog v-model:visible="visible" modal :header="'Case ' + caseId + ' Inspection'" :style="{ width: '80vw' }" maximizable>
    <!-- Case Status -->
    <div v-if="loadedStatus" class="grid py-3 fadein">
        <div class="col-6 flex justify-content-center"
             :style="{
                background:
                    caseStatus.status === 'Pending' ? '#F59E0B' :
                    caseStatus.status === 'Released' ? '#3B82F6' :
                    caseStatus.status === 'Notified' ? '#10B981' :
                    caseStatus.status === 'Removed' ? '#EF4444' : '#6B7280'
                }">

            <p class="font-bold text-2xl my-1">{{ caseStatus.status }}</p>
        </div>

        <div class="col-6 flex justify-content-center"
             :style="{
                background:
                    caseStatus.status === 'Pending' ? '#F59E0B' :
                    caseStatus.status === 'Released' ? '#3B82F6' :
                    caseStatus.status === 'Notified' ? '#10B981' :
                    caseStatus.status === 'Removed' ? '#EF4444' : '#6B7280'
                }">
            <p class="font-bold text-2xl my-1" ref="caseLifeTime">{{ caseLifeTime }}</p>
        </div>
    </div>
    <div v-else class="grid py-3">
        <div class="col-12 flex justify-content-center bg-white text-black-alpha-90">
            <p class="font-bold text-2xl my-1">Loading...</p>
        </div>
    </div>

    <!-- Image Gallery -->
    <div class="w-full grid pt-3">
        <!-- Offending Images -->
        <div v-if="loadedImages" class="col-12 lg:col-6 flex flex-column justify-content-center">
<!--            <span class="font-bold text-center w-full text-xl pb-3">Offending Images</span>-->
            <div class="w-full flex align-items-center justify-content-center flex-grow-1 border-round shadow-1">
                <Galleria :value="listingImages" :responsiveOptions="responsiveOptions" :numVisible="5" :circular="true" containerStyle="max-width: 640px"
                          :showItemNavigators="true" :showThumbnails="false" :showItemNavigatorsOnHover="true" :showIndicators="true" >
                    <template #item="slotProps">
                        <img :src="slotProps.item.image" :alt="'Image with name ' + slotProps.item.Path" style="object-fit: cover; width: 100%; aspect-ratio: 1; display: block;" />
                    </template>
                    <template #thumbnail="slotProps">
                        <img :src="slotProps.item.image" :alt="'Image with name ' + slotProps.item.Path" style="display: block;" />
                    </template>
                </Galleria>
            </div>
        </div>
        <div v-else class="col-12 lg:col-6 flex flex-column justify-content-center">
            <div class="w-full flex align-items-center justify-content-center flex-grow-1 border-round shadow-1">
                <Skeleton class="w-full block h-full" style="min-height: 32rem" />
            </div>
        </div>


        <!-- Official Images -->
        <div v-if="loadedAssetImages" class="col-12 lg:col-6 flex flex-column justify-content-center">
<!--            <span class="font-bold text-center w-full text-xl pb-3">Official Images</span>-->
            <div class="w-full flex align-items-center justify-content-center flex-grow-1 border-round shadow-1">
                <Galleria :value="allAssetImages" :responsiveOptions="responsiveOptions" :numVisible="5" :circular="true" containerStyle="max-width: 640px"
                          :showItemNavigators="true" :showThumbnails="false" :showItemNavigatorsOnHover="true" :showIndicators="true" >
                    <template #item="slotProps">
                        <img :src="slotProps.item.image" :alt="'Image with name ' + slotProps.item.Path" style="object-fit: cover; width: 100%; aspect-ratio: 1; display: block;" />
                    </template>
                    <template #thumbnail="slotProps">
                        <img :src="slotProps.item.image" :alt="'Image with name ' + slotProps.item.Path" style="display: block;" />
                    </template>
                </Galleria>
            </div>
        </div>
        <div v-else class="col-12 lg:col-6 flex flex-column justify-content-center">
            <div class="w-full flex align-items-center justify-content-center flex-grow-1 border-round shadow-1">
                <Skeleton class="w-full block h-full" style="min-height: 32rem" />
            </div>
        </div>
    </div>

    <!-- Case Listing Information -->
    <div class="pt-2"/>
    <div v-if="loadedListing" class="border-round shadow-1 fadein">
        <div class="pt-3"/>
        <span class="font-bold text-center w-full text-xl pl-3">Case Listing Information</span>
        <div class="grid px-3 pb-3">
            <div class="col-12">
                <p class="font-bold">Name</p>
                <span class="w-full p-input-icon-right">
                    <i v-show="!listingReadOnly" class="pi pi-spin pi-spinner"></i>
                    <InputText class="w-full" v-model="caseListing.listingname" :readonly="listingReadOnly" />
                </span>
            </div>

            <div class="col-12">
                <p class="font-bold">Link</p>
                <div class="grid w-full">
                    <div class="col-8">
                        <InputText class="w-full" v-model="caseListing.Link" readonly/>
                    </div>
                    <div class="col-4 px-0">
                        <a v-if="caseListing.Link !== ''" :href="caseListing.Link" target="_blank">
                            <Button class="w-full" label="Open Link" icon="pi pi-external-link" />
                        </a>
                    </div>
                </div>
            </div>


            <div class="col-12 lg:col-6">
                <p class="font-bold">Market Place</p>
                <InputText class="w-full" v-model="caseListing.providername" readonly />
            </div>

            <div class="col-12 lg:col-6">
                <p class="font-bold">Listing ID</p>
                <InputText class="w-full" v-model="caseListing.ListingId" readonly />
            </div>

            <div class="col-12 lg:col-4">
                <p class="font-bold">Price (£)</p>
                <span class="w-full p-input-icon-right">
                    <i v-show="!listingReadOnly" class="pi pi-spin pi-spinner"></i>
                    <InputText class="w-full" v-model="caseListing.Price" :readonly="listingReadOnly" />
                </span>
            </div>

            <div class="col-12 lg:col-4">
                <p class="font-bold">Stock</p>
                <span class="w-full p-input-icon-right">
                    <i v-show="!listingReadOnly" class="pi pi-spin pi-spinner"></i>
                    <InputText class="w-full" v-model="caseListing.Stock" :readonly="listingReadOnly" />
                </span>
            </div>

            <div class="col-12 lg:col-4">
                <p class="font-bold">Sold Volume</p>
                <span class="w-full p-input-icon-right">
                    <i v-show="!listingReadOnly" class="pi pi-spin pi-spinner"></i>
                    <InputText class="w-full" v-model="caseListing.Sold" :readonly="listingReadOnly" />
                </span>
            </div>
        </div>
    </div>
    <div v-else>
        <Skeleton class="w-full block h-full" style="min-height: 24rem" />
    </div>

    <Accordion v-if="loadedImages && loadedStory" :multiple="true" class="pt-3 fadein">
        <!-- Actions -->
        <AccordionTab>
            <template #header>
                <span>Actions</span>
            </template>
            <div class="grid">
                <div class="col-12">
                    <Button class="w-full" :label="'Retry Case Ingest'" icon="pi pi-cog" @click="retryCase" />
                </div>

                <div class="col-6 lg:col-4 pt-2">
                    <Button class="w-full" :class="listingReadOnly ? '' : 'p-button-outlined'"
                            :label="listingReadOnly ? 'Edit Listing' : 'Save'"
                            :icon="listingReadOnly ? 'pi pi-pencil' : 'pi pi-spin pi-spinner'" @click="editCaseListing" />
                </div>
                <div class="col-6 lg:col-4 pt-2">
                    <Button class="w-full" :class="!showImageUpload ? '' : 'p-button-outlined'"
                            :label="!showImageUpload ? 'Upload Images' : 'Save'"
                            :icon="!showImageUpload ? 'pi pi-plus' : 'pi pi-spin pi-spinner'" @click="showImageUpload=!showImageUpload" />
                </div>
                <div class="col-6 lg:col-4 pt-2">
                    <Button class="w-full" :class="!showStoryAddition ? '' : 'p-button-outlined'"
                            :label="!showStoryAddition ? 'Add to Story' : 'Save'"
                            :icon="!showStoryAddition ? 'pi pi-plus' : 'pi pi-spin pi-spinner'" @click="showStoryAddition=!showStoryAddition" />
                </div>

                <div class="col-12 lg:col-6 pt-2">
                    <Button class="w-full" :label="caseStatus.status === 'Pending' ? 'Mark as Released' : caseStatus.status === 'Released' ? 'Mark as Notified' : 'Mark as Closed'" icon="pi pi-arrow-up" @click="escalateCase" />
                </div>

                <div class="col-12 lg:col-6 pt-2">
                    <Button class="w-full" label="Close Case" icon="pi pi-times" @click="closeCase" />
                </div>
            </div>

            <!-- Image Upload -->
            <div v-show="showImageUpload">
                <div class="pb-2"/>
                <Dropdown class="w-full" :options="imageUploadTypes" v-model="selectedImageType" optionLabel="label" placeholder="Select an image type" />
                <div class="pb-2"/>
                <FileUpload name="caseImages[]" :multiple="true"
                            accept="image/*" :maxFileSize="10000000" custom-upload @uploader="uploadImages">
                    <template #empty>
                        <p>Drag and drop files to here to upload.</p>
                    </template>
                </FileUpload>
            </div>

            <!-- Story Upload -->
            <div v-show="showStoryAddition">
                <div class="pb-2"/>
                <Dropdown class="w-full" :options="storyMessageTypes" v-model="selectedStoryType" optionLabel="label" placeholder="Select a message type" />
                <div class="pb-2"/>
                <Textarea class="w-full" v-model="storyEntry" rows="10" />
                <div class="pb-2"/>
                <Button class="w-full" label="Save Story" icon="pi pi-save" @click="addStory" />
            </div>

        </AccordionTab>

        <!-- Case Images -->
        <AccordionTab>
            <template #header>
                <span>Case Images</span>
            </template>
            <TabView>
                <TabPanel v-for="tab in imageTypes" :key="tab.label" :header="tab.label">
                    <div class="grid">
                        <!-- Show images based on the tab and their type -->
                        <div v-for="(image, index) in groupedImages[tab.label]" :key="index" class="col-6 lg:col-3 p-1">
                            <div class="border-1 border-round border-200 hover:surface-200 flex-column justify-content-center p-2 cursor-pointer" @click="onImageClicked(image)">
                                <img :src="image.image" :alt="'Image with name ' + image.Path" style="object-fit: cover; width: 100%; aspect-ratio: 1; display: block;"/>
                                <p class="font-bold text-center">{{ image.Path }}</p>
                            </div>
                        </div>
                    </div>
                </TabPanel>
            </TabView>
        </AccordionTab>

        <!-- Case Story -->
        <AccordionTab>
            <template #header>
                <span>Case Story</span>
            </template>
            <perfect-scrollbar class="overflow-x-scroll">
                <div v-for="entry in caseStory" :key="entry.UTC_DateAdded">
                    <div class="border-bottom-1 border-300 flex align-items-start justify-content-start gap-3">
                        <p class="font-bold pr-3">{{ entry.UTC_DateAdded }}</p>
                        <p class="pr-3">{{ entry.Type }}</p>
                        <p>{{ entry.Description }}</p>
                    </div>
                </div>
            </perfect-scrollbar>
        </AccordionTab>
    </Accordion>
    <div v-else class="pt-3">
        <Skeleton class="w-full block h-full" style="min-height: 16rem" />
    </div>

</Dialog>
</template>

<script>
export default {
    name: "CaseInspection",

    data() {
        return {
            visible: false,

            caseId: 0,
            listingReadOnly: true,
            showImageUpload: false,
            showStoryAddition: false,
            imageUploadTypes: [
                {label: 'Listing', value: 'listing'},
                {label: 'Screenshot', value: 'screenshot'},
                {label: 'Email', value: 'email'},
                {label: 'Other', value: 'other'},
            ],
            selectedImageType: {label: 'Other', value: 'other'},
            storyMessageTypes: [
                {label: 'Note', value: 'note'},
                {label: 'Email', value: 'email'},
                {label: 'Other', value: 'other'},
            ],
            selectedStoryType: {label: 'Other', value: 'other'},
            storyEntry: '',

            loadedImages: false,
            loadedListing: false,
            loadedStory: false,
            loadedAssetImages: false,
            loadedStatus: false,

            allImages: [],
            allAssetImages: [],
            caseListing: {},
            caseStory: {},
            caseStatus: {created: '2000-01-01T00:00:00.000000', status: 'loading'},
            caseLifeTime: `0 days, 0 hours, 0 minutes, 0 seconds`,

            imageTypes: [],
            groupedImages: [],

            responsiveOptions: [
                {
                    breakpoint: '991px',
                    numVisible: 4
                },
                {
                    breakpoint: '767px',
                    numVisible: 3
                },
                {
                    breakpoint: '575px',
                    numVisible: 1
                }
            ]
        }
    },

    computed: {
        listingImages() {
            return this.allImages.filter(image => image.Type === 'listing');
        },
    },

    methods: {
        updateCaseLifeTime() {
            setInterval(() => {
                // Update the computed property
                this.caseLifeTime = this.calcCaseLifeTime();
            }, 1000);
        },

        reset() {
            this.listingReadOnly = true;
            this.showImageUpload = false;
            this.showStoryAddition = false;
            this.selectedImageType = {label: 'Other', value: 'other'};
            this.storyEntry = '';
            this.selectedStoryType = {label: 'Other', value: 'other'};

            this.loadedImages = false;
            this.loadedListing = false;
            this.loadedStory = false;
            this.loadedAssetImages = false;
            this.loadedStatus = false;

            this.allImages = [];
            this.allAssetImages = [];
            this.caseListing = {};
            this.caseStory = {};
            this.caseStatus = {created: '2000-01-01T00:00:00.000000', status: 'loading'};
            this.caseLifeTime = `0 days, 0 hours, 0 minutes, 0 seconds`;

            this.imageTypes = [];
            this.groupedImages = [];
        },

        init() {
            console.debug("Opened case inspection modal", this.caseId);
            this.reset();

            this.getCaseImages();
            this.getCaseListing();
            this.getCaseStory();
            this.getAssetImages();
            this.getCaseStatus();

            this.updateCaseLifeTime();
        },

        show() {
            this.visible = true;
            this.init();
        },

        getCaseImages() {
            this.$factory.cases.getCaseImages(this.caseId)
                .then(response => {
                    console.debug("Case images", response);
                    this.allImages = response.data;
                    console.debug(this.allImages);
                    console.debug(this.listingImages);

                    // Get all the image types
                    this.imageTypes = this.allImages.map(image => image.Type);
                    this.imageTypes = [...new Set(this.imageTypes)];
                    this.imageTypes = this.imageTypes.map(type => {
                        return {
                            label: type,
                            images: this.allImages.filter(image => image.Type === type)
                        }
                    });

                    // Make a dictionary of the images based on their type
                    this.groupedImages = this.allImages.reduce((acc, image) => {
                        if (!acc[image.Type]) {
                            acc[image.Type] = [];
                        }

                        acc[image.Type].push(image);
                        return acc;
                    }, {});

                    this.loadedImages = true;
                })
                .catch(error => {
                    console.error('loaded case images', error)
                    this.emitter.emit('bad-toast', 'Failed to get case images');
                })
        },

        getCaseListing() {
            this.$factory.cases.getCaseListing(this.caseId)
                .then(response => {
                    console.debug("Case listing", response);
                    if (response.data === null) {
                        response.data = {
                            Id: -1,
                            Link: '',
                            ListingId: '',
                            Price: '',
                            Sold: '',
                            Stock: '',
                            UTC_DateCreated: '',
                            listingname: '',
                            providername: '',
                        };
                    }

                    this.caseListing = response.data;
                    this.loadedListing = true;
                })
                .catch(error => {
                    console.error('loaded case listing', error)
                    this.emitter.emit('bad-toast', 'Failed to get case listing');
                })
        },

        getCaseStory() {
            this.loadedStory = false;
            this.$factory.cases.getCaseStory(this.caseId)
                .then(response => {
                    console.debug("Case story", response);

                    // Sort the case story by date
                    response.data.sort((a, b) => {
                        return new Date(b.UTC_DateAdded) - new Date(a.UTC_DateAdded);
                    });

                    this.caseStory = response.data;
                    this.loadedStory = true;
                })
                .catch(error => {
                    console.error('loaded case story', error)
                    this.emitter.emit('bad-toast', 'Failed to get case story');
                })
        },

        getAssetImages() {
            this.$factory.cases.getAssetImagesForCase(this.caseId)
                .then(response => {
                    console.debug("Asset images", response);
                    this.allAssetImages = response.data;
                    this.loadedAssetImages = true;
                })
                .catch(error => {
                    console.error('loaded asset images', error)
                    this.emitter.emit('bad-toast', 'Failed to get asset images');
                })
        },

        getCaseStatus() {
            this.$factory.cases.getCaseStatus(this.caseId)
                .then(response => {
                    console.debug("Case status", response);
                    this.caseStatus = response.data;
                    this.loadedStatus = true;
                })
                .catch(error => {
                    console.error('loaded case status', error)
                    this.emitter.emit('bad-toast', 'Failed to get case status');
                })
        },

        calcCaseLifeTime() {
            const caseCreatedDate = new Date(this.caseStatus.created + 'Z');
            const currentDate = new Date();

            const timeDifference = currentDate - caseCreatedDate;

            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

            return `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;
        },

        editCaseListing() {
            console.debug("Editing case listing", this.caseListing);
            if (this.listingReadOnly) {
                this.listingReadOnly = false;
            } else {
                this.listingReadOnly = true;
                // Save the listing
                this.$factory.cases.updateCaseListing(this.caseId, this.caseListing)
                    .then(response => {
                        console.debug("Updated case listing", response);
                        this.$emitter.emit('good-toast', 'Updated case listing');
                        this.$emitter.emit("case-updated");
                    })
                    .catch(error => {
                        console.error('updated case listing', error)
                        this.$emitter.emit('bad-toast', 'Failed to update case listing');
                    })
            }
        },

        uploadImages(files) {
            console.debug("Uploading images", files);
            this.$factory.cases.uploadCaseImages(this.caseId, files, this.selectedImageType.value, this.caseListing.ListingId)
                .then(response => {
                    console.debug("Uploaded images", response);
                    this.$emitter.emit('good-toast', 'Uploaded images');
                    this.showImageUpload = false;
                    this.getCaseImages();
                    this.getCaseStory();
                })
                .catch(error => {
                    console.error('uploaded images', error)
                    this.$emitter.emit('bad-toast', 'Failed to upload images');
                })
        },

        addStory() {
            console.debug("Adding story to case", this.caseId);
            // Remove newlines from storyEntry
            this.storyEntry = this.storyEntry.replace(/(\r\n|\n|\r)/gm, "");
            this.$factory.cases.addCaseStory(this.caseId, this.selectedStoryType.value, this.storyEntry)
                .then(response => {
                    console.debug("Added story", response);
                    this.$emitter.emit('good-toast', 'Added story');
                    this.showStoryAddition = false;
                    this.storyEntry = '';
                    this.getCaseStory();
                })
                .catch(error => {
                    console.error('added story', error)
                    this.$emitter.emit('bad-toast', 'Failed to add story');
                })
        },

        escalateCase(event) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to escalate this case?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    console.debug("Escalating case", this.caseId, this.caseStatus);
                    this.$factory.cases.escalateCase(this.caseId)
                        .then(response => {
                            console.debug("Escalated case", response);
                            this.$emitter.emit('good-toast', 'Escalated case');
                            this.getCaseStatus();
                            this.$emitter.emit("case-updated");
                        })
                        .catch(error => {
                            console.error('escalated case', error)
                            this.$emitter.emit('bad-toast', 'Failed to escalate case');
                        })
                },
            });
        },

        closeCase(event) {
            console.debug("Closing case", this.caseId);
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to close this case?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    console.debug("Escalating case", this.caseId, this.caseStatus);
                    this.$factory.cases.closeCase(this.caseId)
                        .then(response => {
                            console.debug("Escalated case", response);
                            this.$emitter.emit('good-toast', 'Escalated case');
                            this.getCaseStatus();
                            this.$emitter.emit("case-updated");
                        })
                        .catch(error => {
                            console.error('escalated case', error)
                            this.$emitter.emit('bad-toast', 'Failed to escalate case');
                        })
                },
            });
        },

        retryCase(event) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to retry this case?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    console.debug("Retrying case", this.caseId);
                    this.$factory.cases.retryCase(this.caseId)
                        .then(response => {
                            console.debug("Retried case", response);
                            this.$emitter.emit('good-toast', 'Retrying case, please check back shortly');
                        })
                        .catch(error => {
                            console.error('retried case', error)
                            this.$emitter.emit('bad-toast', 'Failed to retry case');
                        })
                },
            });

        },

        onImageClicked(image) {
            console.debug("Clicked image", image);
            window.open(image.image, '_blank');
        },
    },
}
</script>

<style scoped>
</style>
