<template>
<div class="flex flex-column flex-auto">
    <div class="p-5">
        <!-- Filtering bar -->
        <div class="w-full">
            <div class="surface-card shadow-2 border-round p-3 mb-3 h-full">
                <div>
                    <div class="pb-1">
                        <span class="font-bold">Status</span>
                    </div>
                    <MultiSelect display="chip" v-model="filters.status" :options="filterOptions.statuses" optionLabel="Name"
                                 placeholder="Select a Status" :loading="filtersLoading" class="w-full" />
                </div>
                <div>
                    <div class="pb-1">
                        <span class="font-bold">Marketplaces</span>
                    </div>
                    <MultiSelect display="chip" v-model="filters.marketplace" :options="filterOptions.marketplaces" optionLabel="Name"
                                 placeholder="Select a Marketplace" :loading="filtersLoading" class="w-full" />
                </div>
                <div>
                    <div class="pb-1">
                        <span class="font-bold">Clients</span>
                    </div>
                    <MultiSelect display="chip" v-model="filters.client" :options="filterOptions.clients" optionLabel="Name"
                                 placeholder="Select a Client" :loading="filtersLoading" class="w-full" />
                </div>
                <div>
                    <div class="pb-1">
                        <span class="font-bold">Assets</span>
                    </div>
                    <MultiSelect display="chip" v-model="filters.asset" :options="filterOptions.assets" optionLabel="Name"
                                 placeholder="Select a Asset" :loading="filtersLoading" class="w-full" />
                </div>
            </div>
        </div>

        <!-- Pagination -->
        <div class="surface-card w-full shadow-2 border-round mb-3">
            <Paginator v-model:first="pageFirst" v-model:rows="pageRows" :totalRecords="totalRecords" :rowsPerPageOptions="[30, 60, 120]"
                       template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="({first} - {last} of {totalRecords})"/>
        </div>

        <!-- Grid of cases -->
        <div v-show="!loading" class="w-full grid">
            <div v-for="caseFile in this.filteredCases" v-bind:key="caseFile.id" class="col-6 sm:col-3 lg:col-2">
                <div v-ripple class="relative surface-card shadow-2 border-round p-3 hover:surface-200 transition-duration-150 transition-colors p-ripple h-full flex flex-column justify-content-between">
                    <div class="cursor-pointer" @click="showInspection(caseFile.id)">
                        <!-- Case image -->
                        <div class="flex align-items-center justify-content-center">
                            <div class="relative w-full pb-2">
                                <img v-if="caseFile.image !== null && caseFile.image !== ''" class="border-round" style="width: 100%; aspect-ratio: 1" v-bind:src="caseFile.image">
                                <div v-else class="flex flex-column align-items-center justify-content-center border-1 border-300" style="aspect-ratio: 1; max-height: 100vw; container-type: inline-size">
                                    <i class="pi pi-eye-slash pb-2" style="font-size: 50cqw;"/>
                                    <span class="font-bold" style="font-size: 10cqw">No image found</span>
                                </div>
                            </div>
                        </div>
                        <!-- Case status -->
                        <Tag :style="{
                            background:
                                caseFile.status === 'Pending' ? '#F59E0B' :
                                caseFile.status === 'Released' ? '#3B82F6' :
                                caseFile.status === 'Notified' ? '#10B981' :
                                caseFile.status === 'Removed' ? '#EF4444' : '#6B7280'
                            }">
                            {{ caseFile.status }}
                        </Tag>

                        <!-- Case name -->
                        <p v-if="caseFile.listingname !== ''" class="text-overflow-ellipsis clamp">{{ caseFile.listingname }}</p>
                        <p v-else class="text-overflow-ellipsis clamp">No name found</p>
                    </div>

                    <!-- Marketplace name -->
                    <div class="align-self-start">
                        <a v-if="caseFile.name" class="text-overflow-ellipsis" :href="caseFile.link" target="_blank">{{ caseFile.name }} &nbsp;<i class="pi pi-external-link"/></a>
                        <a v-else class="text-overflow-ellipsis">No marketplace found</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Loading skeletons 18 skeletons -->
        <div v-show="loading" class="w-full grid">
            <div v-for="index in 18" :key="index" class="col-6 sm:col-3 lg:col-2">
                <Skeleton class="w-full h-full" style="aspect-ratio: 0.75"/>
            </div>
        </div>
    </div>
</div>
<CaseInspection ref="inspection"/>
</template>

<script>
import CaseInspection from "@/components/modals/CaseInspection";

export default {
    name: "CaseManager",
    components: {CaseInspection},
    data() {
        return {
            loading: false,
            filtersLoading: true,
            cases: [],

            // Pagination
            pageFirst: 0,
            pageRows: 30,
            totalRecords: 0,

            // Filters
            filters: {
                marketplace: [],
                status: [],
                client: [],
                asset: [],
            },
            filterOptions: {},
        }
    },

    computed: {
        lazy_params() {
            return {
                first: this.pageFirst,
                rows: this.pageRows,
                marketplace: this.filters.marketplace.map(m => m.Name),
                status: this.filters.status.map(s => s.Name),
                client: this.filters.client.map(c => c.Name),
                asset: this.filters.asset.map(a => a.Name),
            }
        },
    },

    mounted() {
        this.getFilters();
        // this.getAllCases();

        this.$emitter.on('case-updated', () => {
            this.getAllCases();
        });
    },

    methods: {
        getFilters() {
            this.$factory.cases.getCaseFilters()
                .then(response => {
                    console.debug(response);
                    this.filterOptions = response.data;

                    // Set default values as Name of each object
                    this.filters.marketplace = this.filterOptions.marketplaces;
                    this.filterOptions.statuses = [{Name: 'Pending'}, {Name: 'Released'}, {Name: 'Notified'}, {Name: 'Removed'}];
                    this.filters.status = [{Name: 'Pending'}, {Name: 'Released'}, {Name: 'Notified'}];
                    this.filters.client = this.filterOptions.clients;
                    this.filters.asset = this.filterOptions.assets;

                    this.getAllCases();
                })
                .catch(error => {
                    console.error('Loading case filters', error)
                    this.$emitter.emit('bad-toast', 'Failed to get case filters');
                })
                .finally(() => {
                    this.filtersLoading = false;
                })
        },

        getAllCases() {
            this.loading = true;

            // Build lazy query
            let query = {
                first: this.pageFirst,
                rows: this.pageRows,
                marketplace: this.filters.marketplace.map(m => m.Name),
                status: this.filters.status.map(s => s.Name),
                client: this.filters.client.map(c => c.Name),
                asset: this.filters.asset.map(a => a.Name),
            }

            console.debug(query);

            this.$factory.cases.getAllCaseInfo(query)
                .then(response => {
                    console.debug(response);
                    this.cases = response.data;
                    this.filteredCases = this.cases;
                    this.totalRecords = response.total;
                })
                .catch(error => {
                    console.error('Loading all case information', error)
                    this.$emitter.emit('bad-toast', 'Failed to get cases');
                })
                .finally(() => {
                    this.loading = false;
                })
        },

        showInspection(caseId) {
            this.$refs.inspection.$data.caseId = caseId;
            this.$refs.inspection.show();
        },
    },

    watch: {
        lazy_params: {
            handler() {
                console.debug(this.lazy_params)
                if (this.filtersLoading) return;
                this.getAllCases();
            },
            deep: true,
        }
    }
}
</script>

<style scoped>
.clamp {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>
